import React from 'react';

class Skills extends React.Component {
    renderSkills() {
        var skills = [
            { name: "C#" },
            { name: ".NET Core Frameworks (5,6,7)" },
            { name: ".NET WebForms" },
            { name: ".NET MVC" },
            { name: "WPF" },
            { name: "WinForms" },
            { name: "Restful Web Services" },
            { name: "WCF Services" },
            { name: "Database Development" },
            { name: "EF Core" },
            { name: "SQL" },
            { name: "MySQL" },
            { name: "PostgreSQL" },
            { name: "MongoDB" },
            { name: "XML" },
            { name: "HTML" },
            { name: "CSS" },
            { name: "Javascript" },
            { name: "JQuery" },
            { name: "Ajax" },
            { name: "RabbitMQ" },
            { name: "Elasticsearch" },
            { name: "SignalR" },
            { name: "LINQ" },
            { name: "Git" },
            { name: "Linux" },
            { name: "Docker" },
            { name: "Nginx" },
            { name: "Hangfire" },
            { name: "Reactjs" },
            { name: "Nextjs" },
            { name: "Flutter" }
        ]
        
        var groupItemCount = Math.ceil(skills.length / 4);
    
        var groups = [];
        for (let i = 0; i < 4; i++) {
            var group = [];
            for (let k = 0; k < groupItemCount; k++){
                var index = (i * groupItemCount) + k;
                if (skills.length > index){
                    group.push(skills[index]);
                }
            }
            groups.push({
                key: i,
                items: group
            });
        }

        return (
            groups.map(group => (
            <div key={group.key} className="col-xl-3">
                <ul className="skills">
                    {
                    group.items.map(item => (
                        <li key={item.name}>{item.name}</li>
                    ))
                    }
                </ul>
            </div>
            ))
        );

    }

    render() {
        return (
            <section id="skills" className="section bg-dark-2">
                <div className="container px-lg-5">
                    <div className="position-relative d-flex text-center mb-5">
                        <h2 className="h1 text-muted opacity-1 text-uppercase fw-600 w-100 mb-0">Skills</h2>
                    </div>

                    <div className="row gy-5">
                        <div className="col-lg-12 col-xl-12 text-center text-lg-start row skill-container">
                            {this.renderSkills()}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Skills;