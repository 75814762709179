import React from 'react';

class Experience extends React.Component {
    renderEducation() {
        var list = [
            {
                key: 1,
                school: "Kırıkkale University",
                startDate: new Date(2010, 9, 1),
                endDate: new Date(2014, 6, 10),
                faculty: "Computer Education And Instructional Technology"
            }
        ];

        return (
            list
                .sort((a, b) => {
                    if (a.endDate > b.endDate) {
                        return -1
                    }
                    if (a.endDate < b.endDate) {
                        return 1
                    }
                    return 0
                })
                .map(item => (
                <div key={item.key} className="bg-dark rounded p-4 mb-4">
                    <p className="badge bg-danger text-2 fw-400">
                        {("0"+(item.startDate.getMonth())).slice(0,2) + "/" + item.startDate.getFullYear() + " - " + ("0"+(item.endDate.getMonth())).slice(0,2) +"/"+ item.endDate.getFullYear()}</p>
                    <h3 className="text-5 text-white">{item.faculty}</h3>
                    <p className="text-primary">{item.school}</p>
                </div>
            ))
        );
    }
    
    renderExperiences() {
        var experiences = [
            {
                key: 1,
                company: "Ticimax Bilişim Teknolojileri A.Ş",
                startDate: new Date(2014, 9, 1),
                endDate: new Date(2015, 3, 1),
                isCurrent: false,
                title: "Junior Software Developer",
                description: "",
            },
            {
                key: 2,
                company: "Ticimax Bilişim Teknolojileri A.Ş",
                startDate: new Date(2015, 3, 1),
                endDate: new Date(2018, 2, 19),
                isCurrent: false,
                title: "Software Developer",
                description: "",
            },
            {
                key: 3,
                company: "Ticimax Bilişim Teknolojileri A.Ş",
                startDate: new Date(2018, 2, 19),
                endDate: new Date(2022, 6, 14),
                isCurrent: false,
                title: "Software Development Team Lead",
                description: "",
            },
            {
                key: 4,
                company: "Petzzshop",
                startDate: new Date(2022, 6, 14),
                endDate: new Date(2023, 3, 1),
                isCurrent: false,
                title: "Software Development Team Lead",
                description: "",
            },
            {
                key: 5,
                company: "REM People",
                startDate: new Date(2023, 3, 1),
                endDate: new Date(),
                isCurrent: true,
                title: "Backend Team Lead",
                description: "",
            },
        ]

        return (
            experiences
                .sort((a, b) => {
                    if (a.endDate > b.endDate) {
                        return -1
                    }
                    if (a.endDate < b.endDate) {
                        return 1
                    }
                    return 0
                })
                .map(item => (
                <div key={item.key} className="bg-dark rounded p-4 mb-4">
                    <p className="badge bg-danger text-2 fw-400">
                        {("0"+(item.startDate.getMonth())).slice(0,2) + "/" + item.startDate.getFullYear() + " - " + (item.isCurrent ? "..." : ("0"+(item.endDate.getMonth())).slice(0,2) +"/"+ item.endDate.getFullYear())}</p>
                    <h3 className="text-5 text-white">{item.title}</h3>
                    <p className="text-primary">{item.company}</p>
                </div>
            ))
        );

    }

    render() {
        return (

            <section id="experience" className="section">
                <div className="container px-lg-5">
                    <div className="position-relative d-flex text-center mb-5">
                        <h2 className="h1 text-muted opacity-1 text-uppercase fw-600 w-100 mb-0">Experience</h2>
                    </div>

                    <div className="row gy-5">
                        <div className="col-md-6">
                            <h2 className="text-6 text-white fw-600 mb-4">Education</h2>
                            {this.renderEducation()}
                        </div>

                        <div className="col-md-6">
                            <h2 className="text-6 text-white fw-600 mb-4">Experiences</h2>
                            {this.renderExperiences()}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Experience;