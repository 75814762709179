import React from 'react';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.toggleResponsiveClass = this.toggleResponsiveClass.bind(this);
        this.state = {
            responsive: false,
        };
    }

    toggleResponsiveClass() {
        const currentState = this.state.responsive;
        this.setState({ responsive: !currentState });
    };

    renderMenu() {
        var menus = [
            { href: "#home", name: "Home" },
            { href: "#about", name: "About Me" },
            { href: "#skills", name: "Skills" },
            { href: "#experience", name: "Experience" },
            //{ href: "#contact", name: "Contact" },
        ]

        return (
            <ul id="topNav" className={(this.state.responsive ? "responsive" : "") + " nav col-12 col-md-auto mb-2 justify-content-center mb-md-0"}>
                {
                    menus.map(item => (
                        <li key={item.name}><a href={item.href} className="nav-link px-2 link-secondary">{item.name}</a></li>
                    ))
                }
                <li className="icon">
                    <a onClick={this.toggleResponsiveClass}>
                        <i className="fa fa-bars"></i>
                    </a>
                </li>
            </ul>
        );
    }

    render() {
        return (
            <div className="header bg-dark">
                <div className="container">
                    <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
                        <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        </a>

                        {this.renderMenu()}

                    </header>
                </div>
            </div>
        )
    }
}

export default Header;