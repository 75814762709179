import React from 'react';

class About extends React.Component {
    render() {
        return (
            <section id="about" className="section">
                <div className="container px-lg-5">
                    <div className="position-relative d-flex text-center mb-5">
                        <h2 className="h1 text-muted opacity-1 text-uppercase fw-600 w-100 mb-0">About Me</h2>
                    </div>

                    <div className="row gy-5">
                        <div className="col-lg-12 col-xl-12 text-center text-lg-start">
                            <h2 className="text-7 text-white fw-600 mb-3">I'm <span className="text-primary">Burak Koray Balcı,</span> a Software Developer</h2>
                            <p className="text-white-50">
                                I am software developer in Istanbul. I have {new Date().getFullYear() - 2014} years of experience in my professional career.
                                I have passionate about coding and building
                                something that will be useful to people. I like
                                challenges, solving problems, learning new
                                technologies.
                            </p>
                        </div>
                        <div className="col-sm-12 col-xl-4">
                            <div className="bg-dark rounded p-4 mb-4 about-block">
                                <p className="badge bg-danger text-2 fw-400"></p>
                                <h3 className="text-5 text-white">Front-End Development</h3>
                                <p className="text-primary">
                                    Pages developed with html, css, javascript, jquery, angular and react 
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4">
                            <div className="bg-dark rounded p-4 mb-4 about-block">
                                <p className="badge bg-danger text-2 fw-400"></p>
                                <h3 className="text-5 text-white">Web Development</h3>
                                <p className="text-primary">
                                    .Net web applications such as web sites, rest apis and microservices.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4">
                            <div className="bg-dark rounded p-4 mb-4 about-block">
                                <p className="badge bg-danger text-2 fw-400"></p>
                                <h3 className="text-5 text-white">App Development</h3>
                                <p className="text-primary">
                                    Various WPF and WinForm applications.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About;