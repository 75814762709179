import React from 'react';
import ReactDOM from 'react-dom';
import Header from './Header';
import Home from './Home';
import About from './About';
import Skills from './Skills';
import Experience from './Experience';
import reportWebVitals from './reportWebVitals';
import logo from './images/bkb.jpg';

import './bootstrap.min.css';
import './fontawesome/css/all.css';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Header />
    <Home
      name="Burak Koray Balcı"
      title="Software Developer"
      email="burak.koray.balci@gmail.com"
      birthYear={1992}
      location="İstanbul"
      profileImage={logo}
      social={{ linkedin: "https://www.linkedin.com/in/burak-koray-balci/", github: "https://github.com/bkbalci", }}
    />
    <About />
    <Skills />
    <Experience />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
