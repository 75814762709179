import React from 'react';

class Home extends React.Component {
    renderSocialLinks() {
        return (
            <ul className="social-icon wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                {this.props.social.facebook ? <li><a target="_blank" rel="noreferrer" href={this.props.social.facebook}><i className="fab fa-facebook"></i></a></li> : null}
                {this.props.social.twitter ? <li><a target="_blank" rel="noreferrer" href={this.props.social.twitter}><i className="fab fa-twitter"></i></a></li> : null}
                {this.props.social.github ? <li><a target="_blank" rel="noreferrer" href={this.props.social.github}><i className="fab fa-github"></i></a></li> : null}
                {this.props.social.instagram ? <li><a target="_blank" rel="noreferrer" href={this.props.social.instagram}><i className="fab fa-instagram"></i></a></li> : null}
                {this.props.social.linkedin ? <li><a target="_blank" rel="noreferrer" href={this.props.social.linkedin}><i className="fab fa-linkedin"></i></a></li> : null}
            </ul>
        );
    }

    render() {
        return (
            <section id="home" className="bg-dark-2">
                <div className="container">
                    <div className="row xs-column-reverse section-separator vertical-middle-content home-padding">
                        <div className="col-xl-6 col-sm-12">
                            <div className="pp wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                <div className="img-border">
                                    <img src={this.props.profileImage} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <div className="home-info">
                                <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">{this.props.name}</h2>
                                <h4 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">{this.props.title}</h4>
                                <ul>
                                    <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                        <i className="fa fa-envelope"></i> <a href={'mailto:'+this.props.email}>{this.props.email}</a>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                        <i className="fa fa-map-marker"></i> {this.props.location}
                                    </li>
                                    <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                        Age : {new Date().getFullYear() - this.props.birthYear}
                                    </li>
                                </ul>
                                {this.props.social ? this.renderSocialLinks() : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Home;